import React from 'react'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser'

import Text from '../components/common/Text'

const BodyText = styled(Text)`
  margin: 0 auto;
  max-width: ${props => props.theme.layout.maxWidthCentered};

  del {
    text-decoration: line-through;
  }

  em {
    font-style: italic;
  }

  hr {
    border-style: solid;
    border-color: red;
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solidred;
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: red !important;
    span {
      background: inherit !important;
    }
  }
`

const PageBody = props => {
  return (
    <BodyText html>
      {ReactHtmlParser(props.body.childMarkdownRemark.html)}
    </BodyText>
  )
}

export default PageBody
